import React from "react";
import '../Style/ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="tech-container">
      <div className="tech-overlay"></div>
      <div className="tech-content">
        <h1>COMING SOON</h1>
        <p>this feature is under process... Stay tuned for something amazing!</p>
      </div>
    </div>
  );
};

export default ComingSoon;
