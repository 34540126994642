import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import '../Style/HelmetDetection.css';
import toast from 'react-hot-toast';
import Loader from '../components/Loader';
import { v4 as uuidv4 } from 'uuid';
 
const HelmetDetection = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [crowdCategory, setCrowdCategory] = useState('');
    const [crowdResults, setCrowdResults] = useState([]); // Store crowd detection results with timestamps
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoPreviewURL, setVideoPreviewURL] = useState(null);
    const [videoName, setVideoName] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [uniqueId, setUniqueId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstFrameReceived, setIsFirstFrameReceived] = useState(false); // New state
 
    const webcamRef = useRef(null);
    const backendURI = "http://192.168.0.175";


    const HelmetVideoUrl = "https://aisa0101.blob.core.windows.net/weapons/helmet.mp4";
 
    // Function to capture a frame from the webcam and send it for crowd detection
    const captureFrameAndSend = useCallback(async () => {
        if (!webcamRef.current) return;
 
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
 
        if (!imageSrc) return;
 
        const blob = await fetch(imageSrc).then((res) => res.blob());
        const formData = new FormData();
        let newUniqueId = uuidv4();
        setUniqueId(newUniqueId);
        formData.append('file', blob, 'frame.png');
 
        setIsLoading(true);
 
        try {
            const response = await axios.post(`${backendURI}/predict-image/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
 
            const prediction = response.data.predictions[0];
            if (prediction) {
                const { tagName } = prediction;
                setCrowdCategory(tagName); // Update detected crowd category
                setShowResults(true); // Show modal with results
                setIsFirstFrameReceived(true);
            } else {
                setCrowdCategory('No predictions');
                setShowResults(true);
                setIsFirstFrameReceived(true);
            }
        } catch (error) {
            console.error('Error during crowd detection:', error);
            toast.error('Error during crowd detection.');
        } finally {
            if (!isFirstFrameReceived) {
                setIsLoading(false);
            }
        }
    }, [webcamRef, backendURI]);
 
    // Capture and process an image every 5 seconds
    useEffect(() => {
        let intervalId;
        if (isCameraActive) {
            intervalId = setInterval(captureFrameAndSend, 5000);
        }
        return () => clearInterval(intervalId);
    }, [captureFrameAndSend, isCameraActive]);
 
    const handleStartCamera = () => {
        setIsCameraActive(true);
        setIsFirstFrameReceived(false);
        setIsLoading(true);
    };
 
    const handleStopCamera = () => {
        setIsCameraActive(false);
        setCrowdCategory('');
        setShowResults(false);
        resetComponent();
    };
 
    const removeUploadedVideo = () => {
        setSelectedVideo(null);
        setVideoPreviewURL(null);
        setVideoName('');
        setCrowdResults([]); // Clear results
    };
 
    const removeResultModel = () => {
        setShowResults(false);
        setCrowdResults([]); // Clear results
    }
 
    const resetComponent = () => {
        setImageSrc(null);
        setCrowdCategory('');
        setShowResults(false);
        if (webcamRef.current) {
            webcamRef.current.video.srcObject?.getTracks().forEach(track => track.stop()); // Stop webcam stream
        }
    };
 
    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        setSelectedVideo(file);
        const videoURL = URL.createObjectURL(file);
        setVideoPreviewURL(videoURL);
        setVideoName(file.name);
        processVideo(file);
    };
 
    // Function to process the video file and extract frames every 5 seconds
    const processVideo = async (file) => {
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);
 
        videoElement.addEventListener('loadeddata', () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
 
            let currentTime = 0;
 
            const processNextFrame = () => {
                if (currentTime <= videoElement.duration) {
                    videoElement.currentTime = currentTime;
 
                    videoElement.onseeked = () => {
                        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                        canvas.toBlob(async (blob) => {
                            const formData = new FormData();
                            formData.append('file', blob, 'video_frame.png');
                            setIsLoading(true);
 
                            try {
                                const response = await axios.post(`https://vision-meridian.azurewebsites.net/predict-image/`, formData, {
                                    headers: { 'Content-Type': 'multipart/form-data' },
                                });
 
                                const prediction = response.data.predictions[0];
                                if (prediction) {
                                    const { tagName } = prediction;
                                    setCrowdResults(prevResults => [
                                        ...prevResults,
                                        { timeStamp: Math.round(currentTime), tagName }
                                    ]);
                                } else {
                                    setCrowdResults(prevResults => [
                                        ...prevResults,
                                        { timeStamp: Math.round(currentTime), tagName: 'No predictions' }
                                    ]);
                                }
 
                                if (!isFirstFrameReceived) {
                                    setIsFirstFrameReceived(true);
                                    setIsLoading(false);
                                }
 
                                // Move to next frame after processing the current one
                                currentTime += 5;
                                processNextFrame();
                            } catch (error) {
                                console.error("Error during file upload:", error);
                            }
                        });
                    };
                } else {
                    // Video processing complete
                    setCrowdResults(prevResults => [
                        ...prevResults,
                        { timeStamp: 'Complete', tagName: 'Video processing complete' }
                    ]);
                    if (!isFirstFrameReceived) {
                        setIsLoading(false);
                    }
                }
            };
 
            processNextFrame();
        });
    };
 
    return (
        <div className="hero-section3">
            <video className="hero-video1" autoPlay loop muted>
                <source src={HelmetVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {isLoading && !isFirstFrameReceived && (
                <>
                    <div className="overlay-loader"></div> {/* Full-screen overlay */}
                    <Loader />
                </>
            )}
            <div className="hero-content1">
                <div className='main-content'>
                    {isCameraActive && showResults ? (
                        <div className='results-table-container'>
                            <div className="">
                                <div className="modal-content1">
                                    <div className="modal-close-icon1" onClick={() => setShowResults(false)}>
                                        &#10060;
                                    </div>
                                    <h5 style={{ textAlign: 'center' }}>Helmet Detection Results:</h5><br />
                                    <table className="crowd-detection-table" style={{ margin: '0 auto' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{crowdCategory}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="lottifile">
                                        <dotlottie-player
                                            src="https://lottie.host/a84b7bab-fffd-44fe-bb2c-ac0287d02077/cL5KVOsVoi.json"
                                            background="transparent"
                                            border="2px solid black"
                                            speed="1"
                                            loop
                                            autoplay
                                            style={{ width: '100%', height: '100%' }}
                                        ></dotlottie-player>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        videoPreviewURL && !isCameraActive && crowdResults.length > 0 ? (
                            <div className="results-table-container">
                                <div className="">
                                    <div className="modal-content1">
                                        {/* Close button */}
                                        <div className="modal-close-icon1" onClick={removeResultModel}>
                                            &#10060;
                                        </div>
                                        <h5>Video Detection Results:</h5>
                                        <div className="results-scrollable">
                                            {/* Display the results in a table */}
                                            <table className="number-plate-table">
                                                <thead>
                                                    <tr>
                                                        <th>Timestamp</th>
                                                        <th>Helmet Detection</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {crowdResults.map((result, index) => (
                                                        result.timeStamp !== 'Complete' ? (
                                                            <tr key={index}>
                                                                <td>
                                                                    {result.timeStamp === 0
                                                                        ? `${result.timeStamp} seconds` : `${result.timeStamp - 5} seconds`}
                                                                </td>
                                                                <td>{result.tagName}</td>
                                                            </tr>
                                                        ) : (
                                                            <tr key={index}>
                                                                <td colSpan="2" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                                    Video processing complete
                                                                </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="tagline1">
                                    <h1>
                                        <span className="main-heading-vehicle">Traffic Control Simplified:<br /></span>
                                        <span className="sub-heading-vehicle">Accurate Monitoring for Safe Environments</span>
                                    </h1>
                                </div>
                                <div className="vehicle-button-container">
                                    {!isCameraActive ? (
                                        <button className="vehicle-start-button" onClick={handleStartCamera}>
                                            Start Camera
                                        </button>
                                    ) : (
                                        <button className="vehicle-stop-button" onClick={handleStopCamera}>
                                            Stop Camera
                                        </button>
                                    )}
                                    <div className="">
                                        <button className="upload-button" onClick={() => document.getElementById('video-upload').click()}>
                                            Upload Video
                                        </button>
                                        <input id="video-upload" type="file" accept="video/*" onChange={handleVideoUpload} style={{ display: 'none' }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
 
                <div className="video-preview-container">
                    {isCameraActive ? (
                        <Webcam ref={webcamRef} screenshotFormat="image/png" width={640} height={480} />
                    ) : videoPreviewURL ? (
                        <div className="video-preview-wrapper">
                            <video controls src={videoPreviewURL} width={640} height={480} autoPlay />
                            <p className='videoname'><b>{videoName}</b></p>
                            <button className="remove-video-button" onClick={removeUploadedVideo}>
                                &#10060;
                            </button>
                        </div>
                    ) : (
                        <dotlottie-player
                            src="https://lottie.host/0e5af786-3027-416c-9e2d-22c0bab583ea/582zA9rBh0.json"
                            background="transparent"
                            border="2px solid black"
                            speed="1"
                            loop
                            autoplay
                            style={{ width: '100%', height: '100%' }}
                        ></dotlottie-player>
                    )}
                </div>
            </div>
        </div>
    );
};
 
export default HelmetDetection;
 
 