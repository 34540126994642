import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import '../Style/FaceVerification.css'; 
import axios from 'axios';
import { postAPIMedia } from '../caller/axiosUrls';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import Analytics from './Analytics'; 

const FaceVerification = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [username, setUsername] = useState('');
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(1);
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const [sender, setSender] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate for route navigation

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  };

  const clearImage = () => {
    setImageSrc(null);
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (sender) return;
    else setSender(true);

    setIsSubmitted(true);

    try {
      const binaryData = atob(imageSrc.split(',')[1]);
      const arrayBuffer = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('image', blob);

      const response = await postAPIMedia('https://faceapi-hansinfo-bkbrgqega3gwf0hx.eastus2-01.azurewebsites.net/verify_face/', formData);
      setIsUserVerified(true);
      toast.success("User Verified");
    } catch (error) {
      setIsUserVerified(false);
      toast.error(error.message);
    } finally {
      setSender(false);
    }
  };

  // Navigate to Analytics when "Proceed to Next Step" is clicked
  const handleProceed = () => {
    navigate('/anlytics');
  };

  return step === 1 ? (
    <div className="face-verification">
      <div className="left-section">
        <h2>Webcam</h2>
        <div className="webcam-container">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="webcam"
          />
          
          <div className="buttons">
            <button className={`${sender ? 'text-dark' : "capture-btn" } btnn`} disabled={sender} onClick={capture}>Capture</button>
            <button className={`${sender ? 'text-dark' : "clear-btn" } btnn`} disabled={sender} onClick={clearImage}>Retake</button>
          </div> 
        </div>
        <div className="upload-container">
          <h2>Upload Image</h2>
          <button className={`${sender ? 'text-dark' : "upload-btn" }`} disabled={sender} onClick={triggerFileUpload}>
            <FontAwesomeIcon icon={faPaperclip} /> Attach File
          </button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      <div className="right-section">
        <h2>Output</h2>
        {imageSrc ? (
          <div className="image-preview">
            <img src={imageSrc} alt="Captured" />
            <button className={`${sender ? 'text-dark' : "submit-btn" } btnn`} disabled={sender} onClick={handleSubmit}>
              {sender ? <ReactLoading type={'spin'} color={'black'} height={30} width={30} /> : "Submit"}
            </button>
            {isUserVerified && isSubmitted && (
              <button className="submit-btn btnn" onClick={handleProceed}>
                Proceed to Next Step
              </button>
            )}
          </div>
        ) : (
          <p className="textpara">No image captured or uploaded.</p>
        )}
      </div>
    </div>
  ) : (
    <Analytics setStep={setStep} /> 
  );
};

export default FaceVerification;
