import React, { useRef } from 'react';
import '../Style/Footer.css';
import { Link } from 'react-router-dom';
import clogo from '../Image/clogo.png';

const Footer = ({ scrollToHome, scrollToContactForm , scrollToSteps, scrollToFAQ}) => {
  return (
    <>
      <footer className="custom-footer bg-dark text-light pt-5">
        <div className="container px-5">
          <div className="row">
            <div className="col-6 col-lg-4">
              <h3 className="footer-title">
                <img src={clogo} alt="Meridian Logo" className="meridian-logo" />
              </h3>
              <p className="footer-text pt-2">Tower B, Office No 1103 & 1104, 11th Floor, Spaze IT Tech Park, Sohna Road, Gurugram - 122001</p>
              <p className="footer-text mb-2">1800-102-2150</p>
              <p className="footer-text">0124-442-9900</p>
            </div>
            <div className="col">
              <h4 className="footer-subtitle">Menu</h4>
              <ul className="footer-list list-unstyled pt-2">
                <li className="footer-list-item py-1" onClick={scrollToHome} style={{ cursor: 'pointer' }}>Home</li>
                <li className="footer-list-item py-1" onClick={scrollToContactForm} style={{ cursor: 'pointer' }}>Contact</li>
              </ul>
            </div>
            <div className="col">
            <h4 className="footer-subtitle">Features</h4>
              <ul className="footer-list list-unstyled pt-2">
                <li className="footer-list-item py-1">
                  <Link to="/face-registration" className="text-light text-decoration-none">Face-Authentication</Link>
                </li>
                <li className="footer-list-item py-1">
                  <Link to="/weapon-detection" className="text-light text-decoration-none">Anomaly Detection</Link>
                </li>
                <li className="footer-list-item py-1">
                  <Link to="/crowd-detection" className="text-light text-decoration-none">Crowd Detection</Link>
                </li>
              </ul>
            </div>
            <div className="col">
              <h4 className="footer-subtitle">Other Links</h4>
              <ul className="footer-list list-unstyled pt-2">
                {/* <li className="footer-list-item py-1">Offices</li> */}
                <li className="footer-list-item py-1" onClick={scrollToSteps} style={{ cursor: 'pointer' }}>Guide</li>
                <li className="footer-list-item py-1" onClick={scrollToFAQ} style={{ cursor: 'pointer' }}>FAQ</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-sm-flex justify-content-between py-1">
            <p className="footer-copy">© 2024 Meridian. All Rights Reserved.</p>
            <p className="footer-links">
              <a href="#" className="text-light text-decoration-none pe-4">Terms of use</a>
              <a href="#" className="text-light text-decoration-none">Privacy policy</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
