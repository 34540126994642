import React, { useState } from 'react';
import '../Style/Analytics.css'; // Import the CSS file for styling

const Analytics = ({ setStep }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="analytics-container">
      <h2>Confirmation</h2>
      <p className="confirmation-message">
        Thank you! Your face verification has been successfully submitted.
      </p>
      <button className='btn-analytics' onClick={openPopup}>View Analytics</button>
      {/* Optional: Restart the process */}
      {/* <button className="restart-btn" onClick={() => setStep(1)}>Start Over</button> */}

      {/* Popup for Power BI Dashboard */}
      {isPopupOpen && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="popup-close" onClick={closePopup}>Close</button>
            <iframe
              src="https://app.powerbi.com/reportEmbed?reportId=4ff4ea50-1bdd-4e3f-89d3-eca10c789ffc&autoAuth=true&ctid=a8801bcb-7990-408e-ab0c-e73eccd70288"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen="true"
              title="Power BI Dashboard"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
