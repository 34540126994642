import React, { useRef, useEffect, useState } from 'react';
import clogo from '../Image/clogo.png';
import '../Style/HomePage.css';
import StudentFeedbackSlider from './StudentFeedbackSlider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BenefitsOfFaceVerification from './BenefitsOfFaceVerification';
import ContactForm from './ContactForm';
import Footer from '../components/Footer';
import logoone from '../Image/logoone.png';
import logotwo from '../Image/logotwo.png';
import logothree from '../Image/logothree.png';
import logofour from '../Image/logofour.png';
import logofive from '../Image/logofive.png';
import logosix from '../Image/logosix.png';
import logoseven from '../Image/logoseven.png';
import logoeight from '../Image/logoeight.png';
import logonine from '../Image/logonine.png';
import logoten from '../Image/logoten.png';
import logoelevn from '../Image/logoeleven.png';
import logotwelv from '../Image/logotwelv.png';
import registration from '../Image/registration.png';
import verification from '../Image/verificatin.jpg';
import Analytics from '../Image/Analytics.jpg';
import security from '../Image/security.png';
import { useMsal } from '@azure/msal-react'; // Import the useMsal hook


const HomePage = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount(); // Get the active account to check authentication
  const userEmail = activeAccount ? activeAccount.username : '';
  // State to track which FAQ is open
  const [openFAQ, setOpenFAQ] = useState(null);
  // Function to toggle FAQ visibility
  const toggleFAQ = (faqNumber) => {
    setOpenFAQ(openFAQ === faqNumber ? null : faqNumber); // Toggle between opening and closing FAQ
  };
  const homeRef = useRef(null);
  const contactFormRef = useRef(null);
  const stepsSectionRef = useRef(null);
  const FAQSectionRef = useRef(null);


  // Scroll to Home section
  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFAQ = () => {
    FAQSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  // Scroll to Contact Form
  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Show toast notification when user is authenticated, but only once
  useEffect(() => {
    if (activeAccount && !localStorage.getItem('toastShown')) {
      toast.success(`User Authenticated`, {
        position: 'top-right',
        autoClose: 3000, // Close after 3 seconds
      });
      localStorage.setItem('toastShown', 'true');
    }
  }, [activeAccount]);

  // Function to handle login redirect
  const handleLogin = () => {
    instance.loginRedirect().catch((error) => {
      console.log(error);
      toast.error('Login failed', {
        position: 'top-right',
        autoClose: 3000,
      });
    });
  };

  // Function to handle logout
  const handleLogout = () => {
    instance.logoutRedirect().then(() => {
      localStorage.removeItem('toastShown'); // Reset toast state on logout
    });
  };
  const scrollToSteps = () => {
    stepsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Function to handle smooth scrolling to ContactForm
  // const scrollToContactForm = () => {
  //   contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  return (
    <div className="home-container">
      <ToastContainer />
      <div  ref={homeRef} className="hero-section">
        <div className="home-content-left">
          <img src={clogo} alt="Meridian Logo" className="meridian-logo" />
          <h1>Empowering Security with Intelligent Detection</h1>
          <p>Revolutionize identity verification and safety with Azure-powered systems. From Face Recognition, Crowd, and Weapon Detection, we offer accurate, efficient, and secure solutions for various industries.</p>
          <div className="button-group">
            {activeAccount ? (
              <>
                {/* <button className="primary-button">Explore Solutions</button> */}
                <button className="primary-button" onClick={handleLogout}>Logout</button>
              </>
            ) : (
              <button className="primary-button" onClick={handleLogin}>Explore Solutions</button>
            )}
            <button className="secondary-button" onClick={scrollToContactForm}>Connect</button>
          </div>
        </div>
        <div className="home-content-right">
          <dotlottie-player
            src="https://lottie.host/03a8f086-272e-4533-81bb-1c9fcf89bbae/nRJpZNvTab.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          />
        </div>
      </div>

      <div className="verification-section">
        <h2>Fast, Reliable, and Secure Detection</h2>
        <div className="verification-cards">
          <div className="verification-card">
            <h3>Detection in </h3>
            <p className="highlight">LESS THAN</p>
            <p>5 SEC</p>
          </div>
          <div className="verification-card">
            <h3>Accuracy</h3>
            <p className="highlight">UPTO</p>
            <p>99%</p>
          </div>
          <div className="verification-card">
            <h3>Clone Detection Rate</h3>
            <p className="highlight">UPTO</p>
            <p>97%</p>
          </div>
          <div className="verification-card">
            <h3>Protection against</h3>
            <p className="highlight">SPOOF ATTACKS</p>
            <p>100%</p>
          </div>
        </div>
      </div>

      <div className="new-block-wrapper" ref={stepsSectionRef}>
        <div className="new-block-heading">
          <h1>READY TO ELEVATE YOUR SECURITY AND DETECTION PROCEDURES?</h1>
          <h2>Streamline Your Detection Process in Three Steps:</h2>
        </div>

        {/* Step 1 */}
        <div className="new-block mergingone mobilesteppone">
          <div className="new-block-content textset">
            <h1>Elevate Security with Advanced Azure Detection </h1>
            <h2>Advanced Detection Solutions</h2>
            <p>
              Experience seamless and precise detection with our Azure-powered systems. Enhance security and operational efficiency with reliable solutions tailored for safety compliance, public monitoring, and threat alerts. Discover the future of comprehensive detection today.
            </p>
          </div>
          <div className="new-block-image bgsteponeclr">
            <img src={registration} alt="faceregistration" />
          </div>
        </div>

        {/* Step 2 */}
        <div className="new-block block-margin">
          <div className="new-block-image">
            <img src={verification} alt="Description of image" />
          </div>
          <div className="new-block-content bgsteptwoclr">
            <h1>Effortless Security with Azure Face Verification</h1>
            <h2>Seamless Integration Across Solutions</h2>
            <p>
              Integrate our advanced detection systems effortlessly into your operations. Our technology ensures smooth implementation and reliable performance across various applications. Powered by Azure, it offers efficient and secure solutions for diverse needs.
            </p>
          </div>
        </div>

        {/* Step 3 */}
        <div className="new-block mobilesteppone">
          <div className="new-block-content">
            <h1>Unlock Insights with Advanced Analytics</h1>
            <h2>Insightful Analytics for Detection Solutions</h2>
            <p>
              Maximize your data with Azure-powered analytics. Gain deep insights into system performance, track key metrics, and visualize trends with intuitive dashboards. Optimize strategies and enhance security with actionable intelligence. Experience the future of advanced analytics today.
            </p>
          </div>
          <div className="new-block-image">
            <img src={Analytics} alt="Description of image" />
          </div>
        </div>

        {/* Step 4 */}
        <div className="new-block pdset">
          <div className="new-block-image bgstepclr imagep">
            <img src={security} alt="Description of image" />
          </div>
          <div className="new-block-content">
            <h1>Stay Ahead with Smart Anomaly Detection</h1>
            <h2>Enhancing Security with Smart Detection</h2>
            <p>
              Ensure top security with Azure-powered anomaly detection. Our system flags unusual elements and potential threats in real-time, providing alerts and insights to keep you ahead. Stay secure with cutting-edge technology designed for comprehensive protection.
            </p>
          </div>
        </div>
      </div>

      <BenefitsOfFaceVerification />

      {/* New Block with Student Feedback and FAQ */}
      <div className="feedback-faq-section">
        <div className="feedback-faq-container">
          {/* Student Feedback Column */}
          <div className="feedback-column">
            <h2>Feedbacks</h2>
            {/* <StudentFeedbackSlider /> */}
          </div>

          {/* FAQ Column */}
          <center>
            <div className="faq-column" ref={FAQSectionRef}>
              <div className="faq-item">
                <button className="faq-button" onClick={() => toggleFAQ(1)}>
                  What types of detection solutions do you offer?
                </button>
                <div className={`faq-content ${openFAQ === 1 ? 'active' : ''}`}>
                  We provide Face Recognition, Crowd Detection, and Weapon Detection, all powered by Azure.
                </div>
              </div>

              <div className="faq-item">
                <button className="faq-button" onClick={() => toggleFAQ(2)}>
                  How does your system ensure accuracy and reliability?
                </button>
                <div className={`faq-content ${openFAQ === 2 ? 'active' : ''}`}>
                  Our solutions use Azure’s advanced technology for precise and reliable results in all detection applications.
                </div>
              </div>

              <div className="faq-item">
                <button className="faq-button" onClick={() => toggleFAQ(3)}>
                  How do you integrate detection systems into existing operations?
                </button>
                <div className={`faq-content ${openFAQ === 3 ? 'active' : ''}`}>
                  Our systems are designed for seamless integration, offering comprehensive support for deployment and maintenance.
                </div>
              </div>

              <div className="faq-item">
                <button className="faq-button" onClick={() => toggleFAQ(4)}>
                  How does your system ensure accuracy and reliability?
                </button>
                <div className={`faq-content ${openFAQ === 4 ? 'active' : ''}`}>
                  Our solutions use Azure’s advanced technology for precise and reliable results in all detection applications.
                </div>
              </div>

              <div className="faq-item">
                <button className="faq-button" onClick={() => toggleFAQ(5)}>
                  What types of detection solutions do you offer?
                </button>
                <div className={`faq-content ${openFAQ === 5 ? 'active' : ''}`}>
                  We provide Face Recognition, Crowd Detection, and Weapon Detection, all powered by Azure.
                </div>
              </div>

            </div>

          </center>
        </div>
      </div>

      {/* Contact Form */}
      <div ref={contactFormRef}>
        <ContactForm userEmail={userEmail} />
      </div>
      {/* Footer */}
      <Footer  scrollToHome={scrollToHome} scrollToContactForm={scrollToContactForm} scrollToSteps={scrollToSteps} scrollToFAQ={scrollToFAQ}/>
    </div>
  );
};

export default HomePage;
