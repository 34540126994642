import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import toast from 'react-hot-toast';
import '../Style/WeaponDetection.css';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';


import { v4 as uuidv4 } from "uuid";
import { postAPIMedia } from '../caller/axiosUrls';

const WeaponDetection = () => {
    const [imageSrc, setImageSrc] = useState(null); // Store webcam snapshot
    const [weaponCategory, setWeaponCategory] = useState(''); // Store weapon detection result
    const [weaponResults, setWeaponResults] = useState([]); // Store results from video with timestamps
    const [isCameraActive, setIsCameraActive] = useState(false); // Track camera state
    const [showResults, setShowResults] = useState(false); // Control modal visibility
    const [selectedVideo, setSelectedVideo] = useState(null); // State to manage uploaded video
    const [videoPreviewURL, setVideoPreviewURL] = useState(null); // URL for video preview
    const [videoName, setVideoName] = useState(''); // State to manage video name
    const [uniqueId, setUniqueId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstFrameReceived, setIsFirstFrameReceived] = useState(false); // New state

    const navigate = useNavigate();

    const handleClick = () => {
        // Navigate to the comingsoon route
        navigate('/comingsoon');
    };
    const WeaponVideoUrl = "https://aisa0101.blob.core.windows.net/weapons/weapon_video.mp4";


    const webcamRef = useRef(null); // Reference to webcam
    const backendURI = "https://faceapi-hansinfo-bkbrgqega3gwf0hx.eastus2-01.azurewebsites.net";

    // Function to capture a frame from the webcam and send it for weapon detection
    const captureFrameAndSend = useCallback(async () => {
        if (!webcamRef.current) return;

        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc); // Store captured image

        if (!imageSrc) return;

        const blob = await fetch(imageSrc).then((res) => res.blob());
        const formData = new FormData();
        let newUniqueId = uuidv4();
        setUniqueId(newUniqueId);
        formData.append('image', blob, 'frame.png');
        formData.append('video_id', newUniqueId);
        formData.append('feed_type', 'live_camera');

        const now = new Date();
        const isDate = new Date(now.getTime());

        const day = String(isDate.getDate()).padStart(2, '0');
        const month = String(isDate.getMonth() + 1).padStart(2, '0');
        const year = isDate.getFullYear();
        const date = `${day}-${month}-${year}`;

        const hours = String(isDate.getHours()).padStart(2, '0');
        const minutes = String(isDate.getMinutes()).padStart(2, '0');
        const seconds = String(isDate.getSeconds()).padStart(2, '0');
        const time = `${hours}:${minutes}:${seconds}`;

        formData.append("date", date);
        formData.append("time", time);

        setIsLoading(true);

        try {
            const response = await postAPIMedia(`${backendURI}/detect-anomalies/`, formData);
            const { reason } = response; // Extract weapon detection result

            setWeaponCategory(reason); // Update result state
            setShowResults(true); // Show modal with results
            console.log(response);

            setIsFirstFrameReceived(true);

        } catch (error) {
            console.error('Error during verification:', error);
            toast.error('Error during verification.');
        } finally {
            if (!isFirstFrameReceived) {
                setIsLoading(false);
            }
        }

    }, [webcamRef, backendURI]);

    useEffect(() => {
        let intervalId;
        if (isCameraActive) {
            intervalId = setInterval(captureFrameAndSend, 5000); // Capture frame every 5 seconds
        }
        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [captureFrameAndSend, isCameraActive]);

    const handleStartCamera = () => {
        setIsCameraActive(true); // Start the webcam
        setIsFirstFrameReceived(false);
        setIsLoading(true)
    };

    const handleStopCamera = () => {
        setIsCameraActive(false); // Stop the webcam
        setWeaponCategory(''); // Clear results
        setShowResults(false); // Hide results modal
        resetComponent(); // Reset state and clear results
    };

    const removeUploadedVideo = () => {
        setSelectedVideo(null);
        setVideoPreviewURL(null);
        setVideoName(''); // Clear video name
        setWeaponResults([]); // Clear weapon detection results
    };

    const removeResultModel = () => {
        setShowResults(false)
        setWeaponResults([]); // Clear results
    }

    const resetComponent = () => {
        setImageSrc(null);
        setWeaponCategory('');
        setShowResults(false);
        if (webcamRef.current) {
            webcamRef.current.video.srcObject?.getTracks().forEach(track => track.stop()); // Stop webcam stream
        }
    };

    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        setSelectedVideo(file);
        const videoURL = URL.createObjectURL(file);
        setVideoPreviewURL(videoURL); // Set the URL for previewing the video
        setVideoName(file.name); // Set the video name
        const newUniqueId = uuidv4();
        processVideo(file, newUniqueId);
    };

    const processVideo = async (file, videoId) => {
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        const processingComplete = new Promise((resolve) => {
            videoElement.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                const context = canvas.getContext('2d');

                // videoElement.play();

                let currentTime = 0;
                const processNextFrame = () => {
                    if (currentTime <= videoElement.duration) {
                        videoElement.currentTime = currentTime;
                        // const timeStamp = videoElement.currentTime;

                        videoElement.onseeked = async () => {
                            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                            canvas.toBlob(async (blob) => {
                                const formData = new FormData();
                                formData.append('image', blob, 'video_frame.png');
                                formData.append('video_id', videoId);
                                formData.append('feed_type', 'video_feed');

                                const now = new Date();
                                const isDate = new Date(now.getTime());

                                const day = String(isDate.getDate()).padStart(2, '0');
                                const month = String(isDate.getMonth() + 1).padStart(2, '0');
                                const year = isDate.getFullYear();
                                const date = `${day}-${month}-${year}`;

                                const hours = String(isDate.getHours()).padStart(2, '0');
                                const minutes = String(isDate.getMinutes()).padStart(2, '0');
                                const seconds = String(isDate.getSeconds()).padStart(2, '0');
                                const time = `${hours}:${minutes}:${seconds}`;

                                formData.append("date", date);
                                formData.append('time', currentTime);

                                setIsLoading(true)

                                try {
                                    const response = await postAPIMedia(`${backendURI}/detect-anomalies/`, formData);
                                    const { reason } = response; // Extract weapon category
                                    console.log(response);

                                    // Store the result with timestamp
                                    setWeaponResults(prevResults => [
                                        ...prevResults,
                                        { timeStamp: Math.round(currentTime), reason }
                                    ]);

                                    if (!isFirstFrameReceived) {
                                        setIsFirstFrameReceived(true);
                                        setIsLoading(false);
                                    }

                                    // Move to the next frame after processing the current one
                                    currentTime += 5;
                                    processNextFrame();

                                } 
                                catch (error) {
                                    console.error("Error during file upload:", error);
                                }
                            });
                        };
                    } else {
                        setWeaponResults(prevResults => [
                            ...prevResults,
                            { timeStamp: 'Complete', reason: 'Video processing complete' }
                        ]);
                        if (!isFirstFrameReceived) {
                            setIsLoading(false);
                        }
                    }
                };
                processNextFrame();
            });
        });
    }

    return (
        <div className="hero-section1">
            <video className="hero-video1" autoPlay loop muted>
                <source src={WeaponVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {isLoading && !isFirstFrameReceived && (
                <>
                    <div className="overlay-loader"></div> {/* Full-screen overlay */}
                    <Loader />
                </>
            )}
            <div className="hero-content1">
                <div className='main-content'>
                    {isCameraActive && showResults ? (
                        // If the camera is active and results are available
                        <div className="results-table-container">
                            <div className="">
                                <div className="modal-content1">
                                    {/* Close button */}
                                    <div className="modal-close-icon1" onClick={() => setShowResults(false)}>
                                        &#10060;
                                    </div><br />
                                    <h5 style={{ textAlign: 'center' }}>Weapon Detection Results:</h5><br />
                                    <table className="crowd-detection-table" style={{ margin: '0 auto' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{weaponCategory}</td>
                                            </tr><br />
                                        </tbody>
                                    </table>
                                    <div className="lottifile">
                                        <dotlottie-player
                                            src="https://lottie.host/a84b7bab-fffd-44fe-bb2c-ac0287d02077/cL5KVOsVoi.json"
                                            background="transparent"
                                            border="2px solid black"
                                            speed="1"
                                            loop

                                            autoplay
                                            style={{ width: '100%', height: '100%' }}
                                        ></dotlottie-player>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : (
                        videoPreviewURL && !isCameraActive && weaponResults.length > 0 ? (
                            <div className="results-table-container">
                                <div className="">
                                    <div className="modal-content1">
                                        {/* Close button */}
                                        <div className="modal-close-icon1" onClick={removeResultModel}>
                                            &#10060;
                                        </div>
                                        <h5>Video Detection Results:</h5>
                                        <div className="results-scrollable">
                                            <table className="number-plate-table">
                                                <thead>
                                                    <tr>
                                                        <th>Timestamp</th>
                                                        <th>Weapon Category</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {weaponResults.map((result, index) => (
                                                        result.timeStamp !== 'Complete' ? (
                                                            <tr key={index}>
                                                                <td>
                                                                    {result.timeStamp === 0
                                                                        ? `${result.timeStamp} seconds` : `${result.timeStamp - 5} seconds`}
                                                                </td>
                                                                <td>{result.reason}</td>
                                                            </tr>
                                                        ) : (
                                                            <tr key={index}>
                                                                <td colSpan="2" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                                    Video processing complete
                                                                </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                    <br />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="tagline1">
                                    <h1>
                                        <span className="main-heading-vehicle">Securing Spaces with Instant Threat Detection<br /></span>
                                        <span className="sub-heading-vehicle">Cutting-Edge Technology to Identify and Mitigate Threats in Real Time</span>
                                    </h1>
                                </div>
                                <div className="vehicle-button-container">
                                    {!isCameraActive ? (
                                        <button className="vehicle-start-button" onClick={handleStartCamera}>
                                            Start Camera
                                        </button>
                                    ) : (
                                        <button className="vehicle-stop-button" onClick={handleStopCamera}>
                                            Stop Camera
                                        </button>
                                    )}
                                    <div>
                                        <button className="upload-button" onClick={() => document.getElementById('video-upload').click()}>
                                            Upload Video
                                        </button>
                                        {/* <button className="upload-button" onClick={handleClick}>
                                            Upload Video
                                        </button> */}
                                        <input id="video-upload" type="file" accept="video/*" onChange={handleVideoUpload} style={{ display: 'none' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>

                {/* Webcam Preview */}
                <div className="video-preview-container">
                    {isCameraActive ? (
                        <Webcam ref={webcamRef} screenshotFormat="image/png" width={640} height={480} />
                    ) : videoPreviewURL ? (
                        <div className="video-preview-wrapper">
                            <video controls src={videoPreviewURL} width={640} height={480} autoPlay />
                            <p className='videoname'><b>{videoName}</b></p>
                            <button className="remove-video-button" onClick={removeUploadedVideo}>
                                &#10060;
                            </button>
                        </div>
                    ) : (
                        <div className='side-lotti2'>
                            <dotlottie-player
                                src="https://lottie.host/19f41043-e409-450b-813f-50a31e85c4a6/ndIPpYTUVM.json"
                                background="transparent"
                                border="2px solid black"
                                speed="1"
                                loop
                                autoplay
                                style={{ width: '100%', height: '100%' }}
                            ></dotlottie-player>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WeaponDetection;