import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import toast from 'react-hot-toast';
import '../Style/VehicleModel.css';
 
const VehicleModel = () => {
    const [imageSrc, setImageSrc] = useState(null); // Store webcam snapshot
    const [detectionResults, setDetectionResults] = useState([]); // Store detection results
    const [isProcessing, setIsProcessing] = useState(false); // Track API request state
    const [showResults, setShowResults] = useState(false); // Control modal visibility
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null); // State to manage uploaded video
    const [videoPreviewURL, setVideoPreviewURL] = useState(null); // URL for video preview
    const [videoName, setVideoName] = useState(''); // State to manage video name
    const [processingMessage, setProcessingMessage] = useState([]); // WebSocket message state
 

    const ANPRVideoUrl = "https://aisa0101.blob.core.windows.net/weapons/bg_video.mp4";

    const backendURI = process.env.REACT_APP_BACKEND_URI;
    const webcamRef = useRef(null); // Reference to webcam
 
    // Preprocess and capture frame from webcam
    const captureFrameAndSend = useCallback(async () => {
        if (!webcamRef.current) return;
 
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc); // Store captured image
 
        if (!imageSrc) return;
 
        // Resize and preprocess image
        const image = new Image();
        image.src = imageSrc;
        image.onload = async () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = image.width / 2; // Resize to half resolution
            canvas.height = image.height / 2;
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            const resizedImageSrc = canvas.toDataURL('image/png');
 
            const blob = await fetch(resizedImageSrc).then((res) => res.blob());
            const formData = new FormData();
            formData.append('image', blob, 'frame.png');
 
            setIsProcessing(true); // Start processing
 
            try {
                const response = await axios.post(`${backendURI}/detect-number-plates/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
 
                const numberPlateTexts = response.data?.number_plate_texts || [];
                const processedResults = numberPlateTexts.filter((text) => text !== '');
 
                setDetectionResults(processedResults.length > 0 ? processedResults : ["No number plates detected"]);
                setShowResults(true); // Show modal with results
 
            } catch (error) {
                console.error('Error during verification:', error);
                toast.error('Error during verification.');
            } finally {
                setIsProcessing(false); // Stop processing after the response is received
            }
        };
    }, [webcamRef, backendURI]);
 
    // Capture and process an image every 5 seconds
    useEffect(() => {
        let intervalId;
        if (isCameraActive) {
            intervalId = setInterval(captureFrameAndSend, 5000); // Every 5 seconds
        }
        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [captureFrameAndSend, isCameraActive]);
 
    const handleStartCamera = () => {
        setIsCameraActive(true); // Start the webcam
    };
 
    const handleStopCamera = () => {
        setIsCameraActive(false); // Stop the webcam
        resetComponent(); // Reset state and clear results
    };
 
    const removeUploadedVideo = () => {
        setSelectedVideo(null);
        setVideoPreviewURL(null);
        setVideoName(''); // Clear video name
        resetComponent(); // Reset state and clear results
    };
 
    const resetComponent = () => {
        setImageSrc(null);
        setDetectionResults([]);
        setShowResults(false);
        setIsProcessing(false);
        if (webcamRef.current) {
            webcamRef.current.video.srcObject?.getTracks().forEach(track => track.stop()); // Stop webcam stream
        }
    };
 
    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        setSelectedVideo(file);
        const videoURL = URL.createObjectURL(file);
        setVideoPreviewURL(videoURL); // Set the URL for previewing the video
        setVideoName(file.name); // Set the video name
        processVideo(file);
    };
 
    const processVideo = async (file) => {
        const formData = new FormData();
        formData.append("video", file);

        // POST request to upload the video
        const uploadResponse = await fetch(`${backendURI}/upload-video/`, {
            method: "POST",
            body: formData,
        });
        const uploadData = await uploadResponse.json();

        if (uploadData.video_path) {
            // Start the WebSocket connection after uploading the video
            startWebSocket(uploadData.video_path);
        }
    };

    const startWebSocket = (videoPath) => {
        const socket = new WebSocket(`${backendURI.replace(/^http/, 'ws')}/ws/process-video`);

        socket.onopen = () => {
            console.log("WebSocket connection opened");
            // Send the video path to the backend for processing
            socket.send(videoPath);
        };

        socket.onmessage = (event) => {
            console.log("Message from server:", event.data);
            setProcessingMessage((prev) => [...prev, event.data]);
        };

        socket.onclose = () => {
            console.log("WebSocket connection closed");
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };
    };
 
    return (
        <div className="hero-section1">
            <video className="hero-video1" autoPlay loop muted>
                <source src={ANPRVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="hero-content1">
                <div className='main-content'>
                    <div className="tagline1">
                        <h1>
                            <span className="main-heading-vehicle">Precision in Every Frame<br /></span>
                            <span className="sub-heading-vehicle">Cutting-Edge Plate Recognition for Enhanced Security and Automation</span>
                        </h1>
                    </div>
                    <div className="vehicle-button-container">
                        {!isCameraActive ? (
                            <button
                                className="vehicle-start-button"
                                onClick={handleStartCamera}
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            >
                                Start Camera
                            </button>
                        ) : (
                            <button
                                className="vehicle-stop-button"
                                onClick={handleStopCamera}
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            >
                                Stop Camera
                            </button>
                        )}
                        <div>
                            <button className="upload-button" onClick={() => document.getElementById('video-upload').click()}>
                                Upload Video
                            </button>
                            <input
                                id="video-upload"
                                type="file"
                                accept="video/*"
                                onChange={handleVideoUpload}
                                style={{ display: 'none' }} // This hides the input
                            />
                        </div>
                    </div>
                </div>
                <div className="video-preview-container">
                    {isCameraActive ? (
                        <Webcam
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            width={640}
                            height={480}
                        />
                    ) : videoPreviewURL ? (
                        <div className="video-preview-wrapper">
                            <video
                                controls
                                src={videoPreviewURL}
                                width={640}
                                height={480}
                                autoPlay
                            />
                            <p className='videoname'><b>{videoName}</b></p>
                            <button className="remove-video-button" onClick={removeUploadedVideo}>
                                &#10060;
                            </button>
                        </div>
                    ) : (
                        <dotlottie-player
                            src="https://lottie.host/0e5af786-3027-416c-9e2d-22c0bab583ea/582zA9rBh0.json"
                            background="transparent"
                            border="2px solid black"
                            speed="1"
                            loop
                            autoplay
                            style={{ width: '100%', height: '100%' }}
                        ></dotlottie-player>
                    )}
                </div>
            </div>
 
            {/* Modal for number plate results */}
            {showResults && (
                <div className="modal-overlay1">
                    <div className="modal-content1">
                        <div className="modal-close-icon1" onClick={() => setShowResults(false)}>
                            &#10060;
                        </div>
                        <h5>Detected Number Plates:</h5>
                        <div className='results-scrollable'>
                            <table className="number-plate-table">
                                <thead>
                                    <tr>
                                        <th>Number Plate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detectionResults.map((plate, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}. {plate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
 
export default VehicleModel;
 
 