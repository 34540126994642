// src/components/Loader.js
import React from 'react';
import '../Style/Loader.css'; // Import the CSS file for the loader styles
 
const Loader = () => {
    return (
        <div className="spinner"></div>
    );
};
 
export default Loader;
 
 