import React from 'react';
import benifits from '../Image/benifits.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/BenefitsOfFaceVerification.css'; // Import your custom CSS
 
const BenefitsOfFaceVerification = () => {
  return (
    <div className="benefits-container container">
      <div className="row">
        <div className="col-lg-6 bgcolorleft">
          <div className="benefits-title">
            <h2>Prime Benefits of Our Advanced Detection Solutions</h2>
            <img
             src={benifits}
             alt="Illustration related to UI Design"
             className="benefits-image"
            />
          </div>
        </div>
        <div className="col-lg-6 bgcolorright">
          <div className="benefits-list">
 
            <div className="benefit-item">
              <div className="benefit-number">1</div>
              <div className="benefit-text">
                <h5>Unmatched Accuracy
                </h5>
                <p>Leverage Azure technology for precise detection in safety compliance, public monitoring, and threat alerts.</p>
              </div>
            </div>
 
            <div className="benefit-item">
              <div className="benefit-number">2</div>
              <div className="benefit-text">
                <h5>Comprehensive Security
                </h5>
                <p>Azure’s encryption and access controls keep your detection data secure from unauthorized access and breaches.</p>
              </div>
            </div>
 
            <div className="benefit-item">
              <div className="benefit-number">3</div>
              <div className="benefit-text">
                <h5>Scalable Solutions
                </h5>
                <p>Our systems scale effortlessly, from small applications to large deployments, ensuring reliable performance.</p>
              </div>
            </div>
 
            <div className="benefit-item">
              <div className="benefit-number">4</div>
              <div className="benefit-text">
                <h5>Operational Efficiency</h5>
                <p>Real-time alerts, anomaly detection, and analytics streamline operations, enhancing safety and decision-making.</p>
              </div>
            </div>
 
          </div>
      </div>
      </div>
    </div>
  );
};
 
export default BenefitsOfFaceVerification;
 
 