import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'
import HomePage from './Pages/HomePage';
// import AboutUs from './Pages/AboutUs';
import FaceRegistration from './Pages/FaceRegistration';
import FaceVerification from './Pages/FaceVerification';
import Analytics from './Pages/Analytics';
import VehicleDetection from './Pages/VehicleModel';
import WeaponDetection from './Pages/WeaponDetection';
import CrowdDetection from './Pages/CrowdDetection';
import HelmetDetection from './Pages/HelmetDetection'
import ComingSoon from './Pages/ComingSoon';
// import ContactUs from './Pages/ContactUs';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { AuthenticatedTemplate,UnauthenticatedTemplate,useMsal,MsalProvider } from '@azure/msal-react';
import { loginRequest } from './auth-config';

const WrappedView = () =>{
  
  const {instance} = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = ()=>{
    instance
      .loginRedirect({
        ...loginRequest,
        prompt:'create',
      })
      .catch ((error) => console.log(error));
  };
  if (activeAccount) {
    instance.setActiveAccount(activeAccount); // Ensure active account is set
  }
  return(
    <>
    </>
  )
}

const App=({instance})=> {

  const [activeAccount, setActiveAccount] = React.useState(null);


  return (
  <Router>
  <Toaster
    position="top-right"
    reverseOrder={true}
  />
  <div style={{ display: 'flex' }}>
    <Navbar />
    <div style={{ flex: 1 }}>
        <MsalProvider instance={instance}>
          <WrappedView/>
          <Routes>
            <Route path="/" element={<HomePage activeAccount={activeAccount} />} />
            {/* <Route path="/about" element={<AboutUs />} /> */}
            <Route path="/face-registration" element={<FaceRegistration />} />
            <Route path="/face-verification " element={<FaceVerification />} />
            <Route path="/anlytics" element={<Analytics />}/>
            <Route path="/vehicle-detection" element={<VehicleDetection />} />
            <Route path="/weapon-detection" element={<WeaponDetection />} />
            <Route path="/crowd-detection" element={<CrowdDetection />} />
            <Route path="/helmet-detection" element={<HelmetDetection />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            {/* <Route path="/contact" element={<ContactUs />} /> */}
          </Routes>
        </MsalProvider>  
    </div>
  </div>
</Router>
  );
}

export default App;