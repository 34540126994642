import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import '../Style/ContactForm.css'; // Import your CSS file

const ContactForm = ({ userEmail }) => {
  const [name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');
  const backendURI = process.env.REACT_APP_BACKEND_URI;

  useEffect(() => {
    if (userEmail) {
      setContactInfo(userEmail);
    }
  }, [userEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      contact_info: contactInfo,  // This will be used as the 'from' email in the backend
      company_name: companyName,
      message,
    };

    try {
      const response = await axios.post(`${backendURI}/send-contact`, formData);
      
      // Display success toast message
      toast.success('Message sent successfully!', {
        position: 'top-right',
      });
    } catch (error) {
      console.error('Error sending contact form:', error);
      
      // Display error toast message
      toast.error('Failed to send message', {
        position: 'top-right',
      });
    }
  };

  return (
    <div className="contact-container">
      <div className="left-content">
        <h2 className="form-title">Let's connect</h2>
        <p className="form-subtitle">
          Have a cool idea for a new project? Need a reliable partner to improve your product? We are here to help you uncomplicate your product development.
        </p>
      </div>
      <div className="form-content">
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            className="form-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Your email"
            className="form-input"
            value={contactInfo}
            onChange={(e) => setContactInfo(e.target.value)}
            required
          />
          <div className="form-row">
            <input
              type="text"
              placeholder="Company"
              className="form-input half-width"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <textarea
            placeholder="Message"
            className="form-textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit" className="form-button btnn">Send Message</button>
        </form>
      </div>

      {/* Toast container for displaying messages */}
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
