import React, {useState} from 'react';
import { NavLink } from 'react-router-dom'; // Use NavLink instead of Link
import favicon from '../Image/favicon2.jpg';
import '../Style/Navbar.css'; // Import the CSS file
import { FaHome, FaCamera, FaCar, FaYoutube, FaTwitter, FaInstagram } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import { MdBlock } from 'react-icons/md';
import { useMsal } from '@azure/msal-react'; // Import the useMsal hook

const Sidebar = () => {

  const [isCollapsed, setIsCollapsed] = useState(false);


  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount(); // Get the active account to check authentication
  const isLoggedIn = activeAccount ? true : false;

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <div className={`darksoul-sidenav ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="header">
          <div className="profile">
            <div className="profile-pic">
              <img src={favicon} alt="Profile Pic" />
            </div>
            <div id="pname" className="profile-name">
              <span>Vision Detection</span>
              <p className="name">Meridian Solution</p>
            </div>
          </div>
          <div className="btn side-new">
            <div id="toggler" className="circle" onClick={toggleSidebar}>
              <img
                id="togglericon"
                width="10"
                height="10"
                src={isCollapsed ? "https://img.icons8.com/metro/26/forward.png" : "https://img.icons8.com/metro/26/back.png"}
                alt="toggle"
              />
            </div>
          </div>
        </div>
        <div className="menus">
          <div className="menu-container">
            <br/>
            <NavLink 
              to="/" 
              className="menu"
              activeClassName="active-link" // Add active class
            >
              <div className="menu-img">
                <FaHome size={30} />
              </div>
              <div className="menu-name">
                <p>Home</p>
              </div>
            </NavLink><br/>
            <NavLink 
              to="/face-registration" 
              className="menu"
              activeClassName="active-link" // Add active class
            >
              <div className="menu-img">
                <FaCamera size={25} />
              </div>
              <div className="menu-name">
                <p>Face Registration</p>
              </div>
            </NavLink><br/>
            {/* <NavLink 
              to="/vehicle-detection" 
              className="menu"
              activeClassName="active-link" // Add active class
            >
              <div className="menu-img">
                <FaCar size={25} />
              </div>
              <div className="menu-name">
                <p>ANPR</p>
              </div>
            </NavLink><br/> */}
            <NavLink 
              to="/weapon-detection" 
              className="menu"
              activeClassName="active-link" // Add active class
            >
              <div className="menu-img">
                <MdBlock size={25} />
              </div>
              <div className="menu-name">
                <p>Weapon Detection</p>
              </div>
            </NavLink><br/>
            <NavLink 
              to="/crowd-detection" 
              className="menu"
              activeClassName="active-link" // Add active class
            >
              <div className="menu-img">
                <IoIosPeople size={25} />
              </div>
              <div className="menu-name">
                <p>Crowd Detection</p>
              </div>
            </NavLink><br/>
            {/* <NavLink 
              to="/helmet-detection" 
              className="menu"
              activeClassName="active-link" // Add active class
            >
              <div className="menu-img">
                <GiFullMotorcycleHelmet size={25} />
              </div>
              <div className="menu-name">
                <p>Helmet Detection</p>
              </div>
            </NavLink><br/> */}
          </div>
        </div>
        <br/><br/>
        <div className="footer">
          <div id="social-media" className="social-media">
            <a href="https://www.youtube.com/channel/UCUu7tZJBEom0EHpXXWg45Vg" target="_blank" rel="noopener noreferrer">
              <FaYoutube size={20} />
            </a>
            <a href="https://x.com/i/flow/login?redirect_after_login=%2FOn_Meridian" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={20} />
            </a>
            <a href="https://www.instagram.com/onmeridian/" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={20} />
            </a>
          </div>
        </div>
      </div>
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <img
          src={
            isCollapsed
              ? "https://img.icons8.com/material-outlined/24/multiply.png" // Close icon
              : "https://img.icons8.com/material-outlined/24/menu--v1.png" // Hamburger icon
          }
          alt={isCollapsed ? "Close" : "Menu"}
        />
      </div>
    </div>
  );
};

export default Sidebar;
