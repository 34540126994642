import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import '../Style/FaceRegistration.css';
import FaceVerification from './FaceVerification';
import Analytics from './Analytics';
import { postAPIMedia } from '../caller/axiosUrls';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
 
const FaceRegistration = () => {
  const [step, setStep] = useState(1); // Track the current step
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if image is submitted
  const [isUserVerified, setIsUserVerified] = useState(false); // Track if user is verified
  const fileInputRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [username, setUsername] = useState(''); // Add a state for username
  const [sender, setSender] = useState(false);
 
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
 
    // Convert the data URL to a binary file
    const byteString = atob(imageSrc.split(',')[1]);
    const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], "captured_image.jpg", { type: mimeString });
 
    setImageFile(file);
  };
 
  const clearImage = () => {
    setImageSrc(null);
    setImageFile(null); // Clear the image file when clearing the image
    setIsSubmitted(false); // Reset submission state when clearing the image
  };
 
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
 
    // Create a preview of the uploaded image
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };
 
  const triggerFileUpload = () => {
fileInputRef.current.click();
  };
 
  const handleSubmit = async () => {
 
    if (sender) return;
    else setSender(true);
 
    if (!username) {
      alert("Please enter a username.");
      return;
    }
  
    if (!imageFile) {
      alert("Please capture or upload an image.");
      return;
    }
  
const registerFaceUrl = `https://faceapi-hansinfo-bkbrgqega3gwf0hx.eastus2-01.azurewebsites.net/register_face/?username=${encodeURIComponent(username)}`;
    
    try {
      const formData = new FormData();
      formData.append("image", imageFile); // Include the image file in FormData
      const response = await postAPIMedia(registerFaceUrl, formData);
      setIsUserVerified(true);
      setIsSubmitted(true);
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSender(false);
    }
  };
 
  const handleStepChange = (newStep) => {
    setStep(newStep);
    const urlMap = {
      1: '/face-registration',
      2: '/face-verification',
      3: '/analytics',
    };
    window.history.pushState({}, '', urlMap[newStep]);
  };
 
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="face-registration">
            <div className="left-section">
              <h2>Webcam</h2>
              <div className="webcam-container">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="webcam"
                />
                <div className="buttons">
                  <button className={`${sender ? 'text-dark' : "capture-btn" } btnn`} onClick={capture} disabled={sender}>Capture</button>
                  <button className={`${sender ? 'text-dark' : "clear-btn" } btnn`} onClick={clearImage} disabled={sender}>Retake</button>
                </div>
              </div>
              <div className="upload-container">
                <h2>Upload Image</h2>
                <button className={`${sender ? 'text-dark' : "upload-btn" } btnn`} disabled={sender} onClick={triggerFileUpload}>
                  <FontAwesomeIcon icon={faPaperclip} /> Attach File
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className="right-section">
              <h2>Output</h2>
              {imageSrc ? (
                <div className="image-preview">
                  <img src={imageSrc} alt="Captured" />
                  <div className="username-container">
                    {/* <h2>Enter Username</h2> */}
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter username"
                      disabled={sender}
                    />
                  </div>
                  <div className='d-flex align-items-center'>
                    <button className={`${sender ? 'text-dark mt-2' : "submit-btn" } btnn`} disabled={sender} onClick={handleSubmit}>{sender ? <ReactLoading type={'spin'} color={'black'} height={30} width={30} /> : "Submit"}</button>
                  </div>
                  {isUserVerified && isSubmitted && (
                    <button className="submit-btn btnn" onClick={() => handleStepChange(2)}>Proceed to Next Step</button>
                  )}
                </div>
              ) : (
                <p className="textpara">No image captured or uploaded.</p>
              )}
            </div>
          </div>
        );
 
      case 2:
        return <FaceVerification />;
 
      case 3:
        return <Analytics />;
 
      default:
        return null;
    }
  };
 
  return (
    <div className='overflow'>
      <nav className="step-nav">
        <button className={`nav-btn ${step === 1 ? 'active' : ''} btnn`} onClick={() => handleStepChange(1)}>Face Registration</button>
        <button className={`nav-btn ${step === 2 ? 'active' : ''} btnn`} onClick={() => handleStepChange(2)}>Face Verification</button>
        <button className={`nav-btn ${step === 3 ? 'active' : ''} btnn`} onClick={() => handleStepChange(3)}>Analytics</button>
      </nav>
      {renderStepContent()}
    </div>
  );
};
 
export default FaceRegistration;